<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  // beforeRouteEnter (to, from, next) {
  //     var userlog = localStorage.getItem('userlogin')
  //     if(userlog == 'lanud') {
  //       next()
  //     } else {
  //       next({
  //         name: 'Pages'
  //       })
  //     }
  // },
//   beforeRouteEnter (to, from, next) {
//       var user = localStorage.getItem('userlogin')
//       if(user == 'lanud') {
//         // this.$router.push('/data_management')
//         next({
//           name: 'DataManagement'
//         })
//       } else if(user == 'asset') {
//         // this.$router.push('/asset_dashboard')
//         next({
//           name: 'AssetDashboard'
//         })
//       } else if(user == 'admin') {
//         // this.$router.push('/dashboard')
//         next({
//           name: 'Dashboard'
//         })
//       } else {
//         next({
//           name: 'Login'
//         })
//       }
//   },
  data () {
    return {
    }
  },
  mounted() {
    this.getDirect()
  },
  methods: {
    getDirect() {
      return this.$router.push('/dashboard')
    }
  }
}
</script>
